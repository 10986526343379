import React, { FunctionComponent } from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Pill from "../../components/Pill"
import Container from "../../components/Container"
import { graphql, Link } from "gatsby"
import ActionPanel from "../../components/home/ActionPanel"
import SignUpForm from "../../components/SignUpForm"
import Highlight from "../../components/home/Highlight"
import Grid from "../../components/Grid"
import styles from "../comparisons.module.css"
import NotifyForm from "../../components/NotifyForm"
import Answer from "../../components/home/Answer"
import Comparison from "../../components/Comparison"
import ComparisonRow from "../../components/ComparisonRow"
import ComparisonSection from "../../components/ComparisonSection"
import GroviaIcon from "../../components/icons/GroviaIcon"

interface QueryResult {
  mdx: {
    frontmatter: {
      title: string
      seo: {
        title: string
        description: string
      }
    }
    body: string
  }
}

interface Props {
  data: QueryResult
}

const GroviaPage: FunctionComponent<Props> = ({ data }) => {
  const page = data.mdx
  // @ts-ignore
  return (
    <Layout>
      <Seo
        description={page.frontmatter.seo.description}
        pathname="/grovia-alternative"
        title={page.frontmatter.seo.title}
      />

      <header className={`hero`} >
        <Pill bgClass="bg-black" variant="bottom-right">
          <div className="py-16 md:py-24 relative z-10">
            <div className="container flex flex-col flex-no-wrap items-center mx-auto text-center text-white">
              <div className="mb-4 md:mb-8 text-white">
                <h1 className="text-6 md:text-8">Breezy: the Grovia alternative for brands looking to seize <Highlight text=" data-driven partnership opportunities " /> wherever they may be</h1>
              </div>

              <div className="mb-8 md:mb-10" style={{ maxWidth: "65ch" }}>
                <p className="text-4">Wondering how Breezy compares to other partner discovery platforms, like Grovia? Recently acquired by Acceleration Partners, Grovia can help brands to accelerate partner discovery, recruitment and revenue activation. But Breezy will give you <em>more results</em> that <em>are higher in relevancy</em>.</p>
              </div>

              <NotifyForm />

            </div>
          </div>
        </Pill>
      </header>


      <section className=" md:py-8 lg:py-20 mt-6 md:mt-16 mx-auto text-3" style={{maxWidth: '140ch'}}>
        <section className="pb-12">
          <Container>
            <section className={`${styles.splash} py-6 text-4`}>
              <h2 className={"font-serif"}>Breezy versus Grovia</h2>
              <p className="mb-4" style={{maxWidth: '75ch'}}>
                Grovia is a pretty cool tool that’s handy for finding and recruiting new partners. It’s quick, it’ll let you search for partners using keywords or competitors, and it’ll show you around 100 results per search. However, if you want <em>more results</em> that are <em>more highly relevant</em>, Breezy has the edge.
              </p>
            </section>

            <section className="mb-6 md:pt-6 md:pt-24 md:mb-12">
                <Grid className="items-center">
                  <section className="col-span-12 sm:pb-8 xl:col-span-5 xl:col-start-8 xl:-mt-4 xl:pb-0">
                    <p className="pb-4">
                      Our partner intelligence and discovery platform searches the whole web to find the best partners for your brand, wherever they may be – unlike Grovia, which pulls its information from networks. Plus, Breezy will give you detailed insights into where you sit within the wider partnership landscape, and let you track your position against your competitors.
                    </p>
                    <p className="pb-4">
                      In short, if you want to be sure you’re making the most of every untapped opportunity, Breezy is the obsessively thorough and data-driven solution you need.
                    </p>
                  </section>

                  <section className="col-span-12 xl:col-span-6 xl:col-start-1 xl:row-start-1">
                    <img alt="Breezy VS Grovia" src="./../images/GroviaVsBreezy.svg"
                         className="inline-block relative"/>                  </section>
                </Grid>
            </section>
          </Container>
        </section>


        <section className="md:py-16 mx-auto px-4 text-2 md:text-3">
          <h2 className="font-serif text-center md:mb-16">Affiliate and partner discovery broken&nbsp;down</h2>
          <Comparison className="md:border-1 md:shadow-xl md:p-6" caption="Features comparison of Breezy vs Grovia" competitor="Grovia" compLogo={<GroviaIcon />}>
            <ComparisonSection label="Inputs">
              <ComparisonRow label="Find prospects based on keywords" valueA={<>Up to 500 keywords per search</>} valueB={<>1 keyword per search</>} />
              <ComparisonRow label="Find prospects based on competitors" valueA={<>Up to 40 competitors per search</>} valueB={<>1 competitor per search</>} />
              <ComparisonRow label="Find partners in specific countries" />
            </ComparisonSection>
            <ComparisonRow label="Lead data" section valueA={<>Receive <strong>1,000s</strong> of leads per search</>} valueB={<>Receive <strong>~ 100</strong> leads per search</>} />
              <ComparisonRow label="Traffic data" />
              <ComparisonRow label="High impact content URLs" />
              <ComparisonRow label="Partner type" valueA={<>Affiliate, Content & Business</>} valueB={<>Content</>} />
              <ComparisonRow label="Geographic audience data" valueB="no" />
              <ComparisonRow label="Relevancy ranking" valueB="no" />
              <ComparisonRow label="Moz Domain Authority" valueB="no" />
              <ComparisonRow label="Similarweb Global Rank" valueB="no" />
              <ComparisonRow label="Existing partnership data" valueB="no" />
              <ComparisonRow label="Affiliate network information" valueB="no" />
              <ComparisonRow label="Alexa Rank" valueA="no" />
              <ComparisonRow label="Monetisation" valueA="no" valueB={<>PPC / Affiliate</>} />
            <ComparisonSection label="Market intelligence">
              <ComparisonRow label="Competitor analysis" valueB="no" />
              <ComparisonRow label="Analyse your existing partners’ new partnerships" valueB="no"/>
              <ComparisonRow label="Insights into the most relevant networks for your brand" valueB="no" />
              <ComparisonRow label="Ecosystem overview" valueA="soon" valueB="no" />
              <ComparisonRow label="Mapped partnerships" valueA="soon" valueB="no" />
              <ComparisonRow label="Gap analysis" valueA="soon" valueB="no" />
            </ComparisonSection>
            <ComparisonRow label="Request leads’ contact information" section />
            <ComparisonSection label="Filtering and organisation" >
              <ComparisonRow label="Custom lists" />
              <ComparisonRow label="PRM folder sequences" />
              <ComparisonRow label="Filter by partner type" />
              <ComparisonRow label="Filter for paid affiliates" />
              <ComparisonRow label="Hide existing partners" valueB="no" />
              <ComparisonRow label="Personalised tagging" valueB="no" />
              <ComparisonRow label="Engage leads with email sequences" valueA="no" />
            </ComparisonSection>
            <ComparisonSection label="Support" >
              <ComparisonRow label="Dedicated discovery success manager" valueB="no" />
              <ComparisonRow label="Help with Live Search setup" valueB="no" />
              <ComparisonRow label="Training on the platform" valueB="no" />
            </ComparisonSection>
            <ComparisonRow label="Pricing" section valueA={<>From £800 per quarter for <strong>1,000s</strong> of leads per month</>} valueB={<>From 141 per quarter for <strong>100s</strong> leads per month</>}  />
          </Comparison>
        </section>



        <section className="py-12 md:py-24">
          <Container>
            <Grid className="items-center">
              <section className="col-span-12 sm:pb-8 xl:col-span-5 xl:-mt-4 xl:pb-0 xxxl:col-start-2">
                <h2>The Grovia competitor that leaves no stone unturned</h2>
                <p className="mb-4">
                  To run a search on Grovia, you just have to enter one keyword or one competitor. Sure, this makes running a search quick and easy – but it will only really uncover low-hanging fruit. Plus, it means you’ll have to run a whole new search for every keyword and competitor you want to look into, which is… well… time consuming!
                </p>
                <p className="mb-4">
                  On the other hand, Breezy can analyse and pull together data from up to 500 keywords and 40 competitors all at once. By combining these data sets, it can yield overlap and give you a thorough set of results that leaves no stone unturned.                </p>
                <p className="mb-4">
                  In fact, while a Grovia search typically yields around 100 results, a Breezy search will scour the whole wide web to give you thousands of highly-relevant leads. Now we’re talking!                </p>

              </section>

              <section className={`col-span-12 xl:col-span-6 xl:col-start-7 ${styles.splash2}`}>
                <img alt="Powerful partner search engine" src="./../images/Uncover.png" className="inline-block relative" style={{width: '100%'}}/>
              </section>
            </Grid>
          </Container>
        </section>

        <section className="py-12 md:py-24">
          <Container>
            <Grid className="items-center">
              <section className="col-span-12 sm:pb-8 xl:col-span-5 xl:col-start-8 xl:-mt-4 xl:pb-0">
                <h2>Breezy is a top Grovia alternative for brands that LOVE data</h2>
                <p className="pb-4">
                  At Breezy, our #1 priority is finding you hyper-relevant leads that could make suitable partners. That’s why we show you detailed insights into each lead – from their monthly traffic to their Moz Domain Authority; their geographic audience data to their Similarweb Global Rank.                </p>
                <p className="pb-4">
                  We’ll even show you a lead’s existing partnerships and, where it’s relevant, we’ll give you info about the affiliate networks where those partnerships are taking place!                </p>
                <p>
                  Grovia is much lighter on data. Although it can help you uncover some fantastic partnership opportunities, you have no way of knowing why those leads have been suggested for you, or of verifying that they’re the right fit for your brand. In other words, when it comes to data, Breezy wins hands down.                </p>
              </section>

              <section className={`col-span-12 xl:col-span-6 xl:col-start-1 xl:row-start-1 ${styles.splash2}`}>
                <img alt="Uncover thousands of leads" src="./../images/DataEveryLead.png" className="inline-block relative" style={{width: '100%'}}/>
              </section>
            </Grid>
          </Container>
        </section>

        <section className="py-12 md:py-24">
          <Container>
            <Grid className="items-center">
              <section className="col-span-12 sm:pb-8 xl:col-span-5 xl:-mt-4 xl:pb-0 xxxl:col-start-2">
                <h2>Hone in your search on the partners that are right for you</h2>
                <p className="pb-4">At Breezy, we understand that every brand is different. So, your partnership goals will be different too! Instead of forcing you to scroll through pages of leads you’re just not interested in, Breezy makes it super easy for you to hone in on the partners that are right for you – with advanced filters, personalised tagging, custom lists and more.</p>
                <p className="pb-4">
                  Want to view only affiliates? No problem! Keen to filter out your existing partnerships? Easy! After leads that have over a set amount of monthly traffic? The sky’s the limit!                </p>
                <p>
                  On the other hand, Grovia doesn’t have many options when it comes to filtering your leads. You’ll also see the same lead crop up in your results pages time and time again, as Grovia lists every relevant URL separately (yep, it can be a bit of a pain!). What we’re trying to say is… if you’re keen to view entities that actually fit with your partner discovery goals, Breezy is the platform that can make it happen.                </p>

              </section>

              <section className={`col-span-12 xl:col-span-6 xl:col-start-7 ${styles.splash2}`}>
                <img alt="Data on every lead" src="./../images/Filtering.png" className="inline-block relative" style={{width: '100%'}}/>
              </section>
            </Grid>
          </Container>
        </section>
        <section className="py-12 md:py-24">
          <Container>
            <Grid className="items-center">
              <section className="col-span-12 sm:pb-8 xl:col-span-5 xl:col-start-8 xl:-mt-4 xl:pb-0">
                <h2>Unlike Grovia, Breezy can show you where you sit in the wider partnership landscape</h2>
                <p className="pb-4">
                  If you’re going to develop a robust partnership strategy, you’ll need to understand where you sit within the partnership landscape as a whole. That’s where Breezy comes into its own!                </p>
                <p className="pb-4">
                  Breezy boasts the world’s biggest partnership database. So far, we’ve mapped more than 7M partnerships across 350 networks, 250K brands and 800K content creators – and we’re not done yet!                 </p>
                <p>
                  This one-of-a-kind database can give you a high-level understanding of the global ecosystem. View spend by partnership type, segment data into niche ecosystems, track your positioning against competitor brands and reveal the untapped opportunity with powerful gap analysis. We’re talking all the partnership insights you could possibly need, on tap!                </p>
              </section>

              <section className={`col-span-12 xl:col-span-6 xl:col-start-1 xl:row-start-1 relative ${styles.splash2}`}>
                <img alt="Affiliate map" src="./../images/AfffMap.png" className="inline-block relative" style={{width: '100%'}}/>
              </section>
            </Grid>
          </Container>
        </section>

        <section className="py-12 md:py-24">
          <Container>
            <Grid className="items-center">
              <section className="col-span-12 sm:pb-8 xl:col-span-5 xl:-mt-4 xl:pb-0 xxxl:col-start-2">
                <h2>Get up-to-date contact information to power your partner recruitment</h2>
                <p className="pb-4">
                  So, you’ve uncovered some awesome-looking leads you’d like to recruit to your partner program. The next step is to contact them!                </p>
                <p className="pb-4">
                  Grovia makes it quick and easy to get the contact details of leads you like the look of with just a couple of clicks. It even has a tool where you can draft email templates and build out sequences to make your outreach that bit easier! However, what Grovia can’t do is tell you whether the contact details it has listed for a lead are up-to-date and relevant – that’s where Breezy has the edge.                </p>
                <p>
                  Rather than using network directories and APIs to source our contact information, we find contact details for the leads you like on request. That means you can be sure the details we give you are totally up-to-date and accurate. We even carry out thorough checks on all the info we find – and if, for whatever reason, the quality isn’t as good as we’d like, the contact request won’t count towards your monthly allowance. Simple!                </p>
              </section>

              <section className={`col-span-12 xl:col-span-6 xl:col-start-7 ${styles.splash2}`}>
                <img alt="Discover potential partners" src="./../images/Contacts.png" className="inline-block relative" style={{width: '100%'}}/>
              </section>
            </Grid>
          </Container>
        </section>

        <section className="py-12 md:py-24">
          <Container>
            <Grid className="items-center">
              <section className="col-span-12 sm:pb-8 xl:col-span-5 xl:col-start-8 xl:-mt-4 xl:pb-0">
                <h2>Enjoy support from Breezy’s dedicated team of experts, whatever your plan</h2>


                <p className="pb-4">
                  Grovia has a variety of plans available, ranging from Self-Serve to Concierge and Enterprise. The level of support you get from their team will vary from nothing for the lower price bands to full-service partner recruitment for the higher ones.                </p>
                <p className="pb-4">
                  However, at Breezy, we recognise how important hands-on support is to helping you succeed. Hence why you’ll get assigned a dedicated discovery success manager, regardless of what plan you choose. They’ll set up your first search with you to show you how it’s done – and they’ll even train your team to make sure they’re fully confident using the Breezy software to level up your partnership game.                 </p>
                <p>
                  We also offer a fully managed solution for brands after a hands-off approach – but either way, you can be sure you have our full support and attention. Nice!                </p>
              </section>

              <section className={`col-span-12 xl:col-span-6 xl:col-start-1 xl:row-start-1 relative ${styles.splash2}`}>
                <img alt="Competitor partner" src="./../images/CS.png" className="inline-block relative" style={{width: '100%'}}/>
              </section>
            </Grid>
          </Container>
        </section>
      </section>

      <section>
          <div className="py-12 md:py-24">
            <ActionPanel>
              <h2 className="mb-6 text-4 md:mb-8 md:text-8">
                Book a demo
              </h2>

              <p className="mb-10 md:mb-16">
                To see exactly what Breezy can do for you and your business, book a free, no-obligation demo with a member of our team. Pssst: you’ll even get some free leads out of it!
              </p>

              <div className="max-w-xl mx-auto">
                <SignUpForm/>
              </div>
            </ActionPanel>
          </div>
      </section>


      <div className="bg-green-100">
          <div className="px-4 pt-24 xxl:pt-32 mx-auto" style={{maxWidth: '70ch'}}>
            <h2 className="mb-16 font-serif text-center">Frequently asked questions</h2>
            <ul itemScope itemType="https://schema.org/FAQPage">
              <Answer title="What are the main similarities and differences between Breezy and Grovia?">
                <p className="text-3 pb-4">Breezy and Grovia are both partner search tools that can help you to discover untapped partnership opportunities for your brand. However, Breezy is more thorough – it will give you more results that are higher in relevancy. It can give you also give you more detailed insights into leads, competitors and the partnership landscape as a whole.</p>
              </Answer>
              <Answer title="Is Breezy a good Grovia alternative?">
                <p className="pb-4 text-3">Yes, Breezy is an excellent Grovia alternative. It has the edge over Grovia when it comes to relevance, thoroughness, filtering and organisation, and contact information quality. </p>
                <p className="text-3 pb-4">Grovia generates results more quickly than Breezy. And, unlike Breezy, it has some outreach tools to help facilitate partner recruitment. However, when it comes to partner discovery, Breezy is the platform that can make sure you leave no stone unturned.</p>
              </Answer>
              <Answer title="Are Grovia and Acceleration Partners the same thing?">
                <p className="text-3 pb-4">No. Acceleration Partners is a top affiliate marketing agency, while Grovia is a partner discovery and recruitment solution. Acceleration Partners recently acquired Grovia, and the two solutions can complement one another. However, the Grovia product, service and brand will continue to be standalone.</p>
              </Answer>
              <Answer title="Who are the main Grovia competitors?">
                <p className="text-3 pb-4">The most direct Grovia competitors are Breezy and Publisher Discovery. You can take a look at how Breezy compares to Publisher Discovery by reading our <Link to="/comparisons/publisher-discovery-alternative">Breezy versus Publisher Discovery comparison</Link> piece.</p>
                <p className="text-3 pb-4">Other less direct competitors include digital marketing platforms like <Link to="/comparisons/similarweb-alternative">Similarweb</Link> and <Link to="/comparisons/semrush-alternative">SEMrush</Link>. These competitors offer a wide suite of tools including some that help with partner discovery – however, they tend to be less advanced when it comes to partner discovery as it isn’t their main focus.</p>
              </Answer>
            </ul>
          </div>
          <div className="py-24 xxl:py-32 text-center">
            <h2>Have more questions?</h2>
            <p className="text-6">Email us at <a href="mailto:welcome@breezy.io">welcome@breezy.io</a></p>
          </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query Grovia {
    mdx(fileAbsolutePath: { regex: "//static/breezy-grovia-alternative/" }) {
      frontmatter {
        title
        seo {
          description
          title
        }
      }
      body
    }
  }
`

export default GroviaPage
